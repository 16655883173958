<template>
	<form @submit.prevent="handleSubmit(!v$.$invalid)" class="p-grid">
		<Toast />
		<ConfirmDialog></ConfirmDialog>
		
		<div class="grid p-fluid stickBar">
			<div class="col-12">
				<div class="card card-w-title" id="stickBar">
					<Menubar :model="nestedMenuitems">
						<template #end>
							<div class="flex justify-content-end flex-wrap card-container green-container">
								<div class="flex align-items-center justify-content-center font-bold text-gray-500 text-xl m-2">
									OTOMASYON PROJESİ İMALAT
								</div>
								<div class="flex align-items-center justify-content-center m-2">
									<Button class="p-button-rounded p-button-danger mr-0 mb-0" v-if="$router.options.history.state.back != null" icon="pi pi-times" @click="$router.go(-1)"/>
								</div>
							</div>
						</template>
					</Menubar>
				</div>
			</div>
		</div>

		<div class="grid">
			<div class="col-12">
				<div class="card">
					<EntityHeader label="OTOMASYON PROJESİ İMALAT" :entityName="ENTITY_NAME"></EntityHeader>
					<div class="grid">
						<div class="col-3">
							<div class="field p-fluid">
								<EntityLookup id="bm_imalatcifirma" v-model="mainData.bm_imalatcifirmaName" ref="entity_bm_imalatcifirma"
									label="İmalatçı Firma" entityName="bm_otomasyonimalatcifirma" nameField="bm_name" :state="true" :required="true" :disabled="isDevreDisi"
									@itemSelected="bm_imalatcifirmaSelected = $event" @itemCleared="bm_imalatcifirmaSelected = null">
								</EntityLookup>
								<span v-if="v$.mainData.bm_imalatcifirmaName.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.bm_imalatcifirmaName.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-2">
							<div class="field p-fluid">
								<label for="bm_tip">Tip <span style="color:red">*</span></label>
								<Dropdown id="bm_tip" v-model="mainData.bm_tip" :options="SM_bm_tip" optionLabel="Value" optionValue="AttributeValue" placeholder="Tip" :showClear="true" :disabled="isDevreDisi" />
								<span v-if="v$.mainData.bm_tip.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.bm_tip.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-2">
							<div class="field p-fluid">
								<label for="urungrubu">Ürün Grubu <span style="color:red">*</span></label>
								<InputText id="urungrubu" type="text" v-model="mainData.bm_urungrubu" :disabled="isDevreDisi" />
								<span v-if="v$.mainData.bm_urungrubu.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.bm_urungrubu.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-5">
							<div class="field p-fluid">
								<label for="ekip">Ekip</label>
								<MultiSelect v-model="selectedMobilKullanicilar" :options="mobilKullanicilarData" optionLabel="name" placeholder="Ekip seçiniz" :filter="true" class="multiselect-custom" :disabled="isDevreDisi">
									<template #value="slotProps">
										<div class="country-item country-item-value" v-for="option of slotProps.value" :key="option.code">
											<img src="layout/images/entityImages/user.gif" class="mr-2" width="18" />
											<div>{{option.name}}</div>
										</div>
										<template v-if="!slotProps.value || slotProps.value.length === 0">
											Ekip seçiniz
										</template>
									</template>
									<template #option="slotProps">
										<div class="country-item">
											<img src="layout/images/entityImages/user.gif" class="mr-2" width="18" />
											<div>{{slotProps.option.name}}</div>
										</div>
									</template>
								</MultiSelect>
							</div>
						</div>
						<div class="col-12">
							<div class="field p-fluid">
								<label for="ozelnot">Özel Not</label>
								<Textarea id="ozelnot" rows="4" :autoResize="false" v-model="mainData.bm_ozelnot" :disabled="isDevreDisi" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="grid">
			<div class="col-12">
				<div class="card">
					<h5>TARİH BİLGİLERİ</h5>
					<div class="grid">
						<div class="col-3">
							<div class="field p-fluid">
								<label for="bm_baslangictarihi">Başlangıç Tarihi <span style="color:red">*</span></label>
								<Calendar showButtonBar id="bm_baslangictarihi" v-model="mainData.bm_baslangictarihi" autocomplete="off" :showIcon="true"  :disabled="isDevreDisi"/>
								<span v-if="v$.mainData.bm_baslangictarihi.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.bm_baslangictarihi.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="bm_bitistarihi">Bitiş Tarihi <span style="color:red">*</span></label>
								<Calendar showButtonBar id="bm_bitistarihi" v-model="mainData.bm_bitistarihi" autocomplete="off" :showIcon="true"  :disabled="isDevreDisi"/>
								<span v-if="v$.mainData.bm_bitistarihi.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.bm_bitistarihi.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="bm_xgunoncehatirlat">x Gün Önce Hatırlat</label>
								<Dropdown id="bm_xgunoncehatirlat" v-model="mainData.bm_xgunoncehatirlat" :options="SM_bm_xgunoncehatirlat" optionLabel="Value" optionValue="AttributeValue" placeholder="x gün Önce Hatırlat" :showClear="true" :disabled="isDevreDisi" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<br>
		<Accordion :activeIndex="0">
			<AccordionTab>
				<template #header>
					<span style="font-size: 1.25rem; font-weight: 500; margin-right: 50px;">İLERLEME</span>
					<Dropdown id="bm_ilerlemedurumu" v-model="mainData.bm_ilerlemedurumu" :options="SM_bm_ilerlemedurumu" optionLabel="Value" optionValue="AttributeValue" placeholder="İlerleme Durumu" :disabled="true">
						<template #value="slotProps">
							<div v-if="slotProps.value" class="flex align-items-center">
								<img :src="getIlerlemeIcon(slotProps.value)" class="mr-2" width="18" />
								<div>{{ slotProps.value }} %</div>
							</div>
							<span v-else>
								{{ slotProps.placeholder }}
							</span>
						</template>
						<template #option="slotProps">
							<div class="country-item">
								<img :src="getIlerlemeIcon(slotProps.option.Value)" class="mr-2" width="18" />
								<div>{{slotProps.option.Value}}</div>
							</div>
						</template>
					</Dropdown>
				</template>
				
				<div class="grid pt-3">
					<div class="col-4">
						<div class="col-12">
							<div class="p-inputgroup">
								<h5><img src="layout/images/entityImages/process_0.png" width="21" /> AŞAMA 1 (0% - 50%)</h5>
							</div>
						</div>
						<div class="col-12">
							<div class="p-inputgroup">
								<span class="p-inputgroup-addon p-inputgroup-addon-checkbox">
									<Checkbox v-model="mainData.bm_ilerleme_check10" :binary="true" @change="enBuyukIlerlemeDegeri"/>
								</span>
								<InputText v-model="mainData.bm_ilerleme_text10" placeholder="%10 Açıklaması" :disabled="!mainData.bm_ilerleme_check10" />
							</div>
						</div>
						<div class="col-12">
							<div class="p-inputgroup">
								<span class="p-inputgroup-addon p-inputgroup-addon-checkbox">
									<Checkbox v-model="mainData.bm_ilerleme_check20" :binary="true" @change="enBuyukIlerlemeDegeri"/>
								</span>
								<InputText v-model="mainData.bm_ilerleme_text20" placeholder="%20 Açıklaması" :disabled="!mainData.bm_ilerleme_check20" />
							</div>
						</div>
						<div class="col-12">
							<div class="p-inputgroup">
								<span class="p-inputgroup-addon p-inputgroup-addon-checkbox">
									<Checkbox v-model="mainData.bm_ilerleme_check30" :binary="true" @change="enBuyukIlerlemeDegeri"/>
								</span>
								<InputText v-model="mainData.bm_ilerleme_text30" placeholder="%30 Açıklaması" :disabled="!mainData.bm_ilerleme_check30" />
							</div>
						</div>
						<div class="col-12">
							<div class="p-inputgroup">
								<span class="p-inputgroup-addon p-inputgroup-addon-checkbox">
									<Checkbox v-model="mainData.bm_ilerleme_check40" :binary="true" @change="enBuyukIlerlemeDegeri"/>
								</span>
								<InputText v-model="mainData.bm_ilerleme_text40" placeholder="%40 Açıklaması" :disabled="!mainData.bm_ilerleme_check40" />
							</div>
						</div>
						<div class="col-12">
							<div class="p-inputgroup">
								<span class="p-inputgroup-addon p-inputgroup-addon-checkbox">
									<Checkbox v-model="mainData.bm_ilerleme_check50" :binary="true" @change="enBuyukIlerlemeDegeri"/>
								</span>
								<InputText v-model="mainData.bm_ilerleme_text50" placeholder="%50 Açıklaması" :disabled="!mainData.bm_ilerleme_check50" />
							</div>
						</div>
					</div>
					<div class="col-4">
						<div class="col-12">
							<div class="p-inputgroup">
								<h5><img src="layout/images/entityImages/process_50.png" width="21" /> AŞAMA 2 (60% - 90%)</h5>
							</div>
						</div>
						<div class="col-12">
							<div class="p-inputgroup">
								<span class="p-inputgroup-addon p-inputgroup-addon-checkbox">
									<Checkbox v-model="mainData.bm_ilerleme_check60" :binary="true" @change="enBuyukIlerlemeDegeri"/>
								</span>
								<InputText v-model="mainData.bm_ilerleme_text60" placeholder="%60 Açıklaması" :disabled="!mainData.bm_ilerleme_check60" />
							</div>
						</div>
						<div class="col-12">
							<div class="p-inputgroup">
								<span class="p-inputgroup-addon p-inputgroup-addon-checkbox">
									<Checkbox v-model="mainData.bm_ilerleme_check70" :binary="true" @change="enBuyukIlerlemeDegeri"/>
								</span>
								<InputText v-model="mainData.bm_ilerleme_text70" placeholder="%70 Açıklaması" :disabled="!mainData.bm_ilerleme_check70" />
							</div>
						</div>
						<div class="col-12">
							<div class="p-inputgroup">
								<span class="p-inputgroup-addon p-inputgroup-addon-checkbox">
									<Checkbox v-model="mainData.bm_ilerleme_check80" :binary="true" @change="enBuyukIlerlemeDegeri"/>
								</span>
								<InputText v-model="mainData.bm_ilerleme_text80" placeholder="%80 Açıklaması" :disabled="!mainData.bm_ilerleme_check80" />
							</div>
						</div>
						<div class="col-12">
							<div class="p-inputgroup">
								<span class="p-inputgroup-addon p-inputgroup-addon-checkbox">
									<Checkbox v-model="mainData.bm_ilerleme_check90" :binary="true" @change="enBuyukIlerlemeDegeri"/>
								</span>
								<InputText v-model="mainData.bm_ilerleme_text90" placeholder="%90 Açıklaması" :disabled="!mainData.bm_ilerleme_check90" />
							</div>
						</div>
					</div>
					<div class="col-4">
						<div class="col-12">
							<div class="p-inputgroup">
								<h5><img src="layout/images/entityImages/process_100.png" width="21" /> AŞAMA 3 (100%)</h5>
							</div>
						</div>
						<div class="col-12">
							<div class="p-inputgroup">
								<span class="p-inputgroup-addon p-inputgroup-addon-checkbox">
									<Checkbox id="ilermeCheckbox100" v-model="mainData.bm_ilerleme_check100" :binary="true" @change="enBuyukIlerlemeDegeri" />
								</span>
								<InputText v-model="mainData.bm_ilerleme_text100" placeholder="%100 Açıklaması" :disabled="!mainData.bm_ilerleme_check100" />
							</div>
						</div>
					</div>
				</div>
			</AccordionTab>
		</Accordion>
	</form>
</template>

<script>
import CrmService from "../service/CrmService";
import { useVuelidate } from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";

export default {
	setup: () => ({ v$: useVuelidate() }),
	data() {
		return {
			crmService: null,
			submitted: false,
			OBJECT_TYPE_CODE: 10049,
			ENTITY_NAME: 'bm_otomasyonimalat',
			ENTITY_SUBJECT: 'Otomasyon Projesi',
			mainData: {},

			mobilKullanicilarData: [],
			selectedMobilKullanicilar: null,

			nestedMenuitems: [
				{
					label:'Kaydet',
					icon:'pi pi-fw pi-save',
					command: () => { this.OnSave(false); },
				},
				{
					label:'Kaydet & Kapat',
					icon:'pi pi-fw pi-save',
					command: () => { this.OnSave(true); },
				},
				{
                    label: "Sil",
                    icon: "pi pi-fw pi-trash",
                    command: () => { this.OnDelete(); },
					visible: () => this.isDeleted
                },
				{
					label:'Devre Dışı Bırak',
					icon:'pi pi-fw pi-check-circle',
					visible: () => !this.isDevreDisi,
					command: () => { this.OnDevreDisiBirak(); },
				},
				{
					label: 'Yeniden ETKİNLEŞTİR',
					icon: 'pi pi-fw pi-history',
					visible: () => this.isDevreDisi,
					command: () => { this.OnYenidenEtkinlestirItem(); },
                },
			],
		}
	},
	async created() {
		this.crmService = new CrmService();
		console.log('created');

		window.addEventListener('scroll', this.handleScroll);

		let mobilKullaniciDataTemp = await this.crmService.GetMobilKullanicilarOtomasyon();
		debugger;
		if (mobilKullaniciDataTemp != null) {
			this.mobilKullanicilarData = mobilKullaniciDataTemp.jsonData;
		}

		this.OnLoad();
    },
	computed: {
		entityId() {
			debugger;
			return this.$route.params.id;
		},
		otomasyonProjesiId() {
			debugger;
			return this.$route.params.id;
		},
		profileData(){
			return this.$store.getters.getProfile;
		},
		isDevreDisi: function() {
			if (this.isYazmaYetkisiVarmi == false) {
				return true;
			}

			if (this.mainData["statecode"]) {
				if (this.mainData["statecode"] == 1) {
					return true;
				}
			}

			return false;
		},
		SM_bm_tip: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_tip');
		},
		SM_bm_ilerlemedurumu: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_ilerlemedurumu');
		},
		SM_bm_xgunoncehatirlat: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_xgunoncehatirlat');
		},
		bm_imalatcifirmaSelected: {
			get: function () {
				if (this.mainData["bm_imalatcifirmaId"]) {
					return { "Value": this.mainData["bm_imalatcifirmaId"], "Name": this.mainData["bm_imalatcifirmaName"] }
				} else{ return null; }
			},
			set: function(newValue) {
				if (newValue == null) {
					this.mainData["bm_imalatcifirmaId"] = null;
					this.mainData["bm_imalatcifirmaName"] = null;
				}
				else {
					this.mainData["bm_imalatcifirmaId"] = newValue.Value;
					this.mainData["bm_imalatcifirmaName"] = newValue.Name;
				}
			}
		},
	},
	methods: {
		async OnLoad(){
			let loader = this.$loading.show({
				container: this.$refs.smsContainer
			});

			debugger;
			if (this.entityId != null) {
				try {
					debugger;

					let data = await this.crmService.getOtomasyonImalatById(this.entityId);
					if (data.yetkiVarmi == false) {
						this.$router.replace({ name: 'accessdenied' });
					}
					
					if (data.jsonData.length > 0) {
						this.mainData = data.jsonData[0];
						this.selectedMobilKullanicilar = data.ekip;

						setTimeout(() => {
							if (this.mainData["bm_imalatcifirmaId"]) {
								this.$refs.entity_bm_imalatcifirma.setDefaultData({ "Value": this.mainData["bm_imalatcifirmaId"], "Name": this.mainData["bm_imalatcifirmaName"] });
							}
						}, 1000);

						if (this.mainData["bm_baslangictarihi"]) {
							this.mainData["bm_baslangictarihi"] = new Date(this.mainData.bm_baslangictarihi);
						}

						if (this.mainData["bm_bitistarihi"]) {
							this.mainData["bm_bitistarihi"] = new Date(this.mainData.bm_bitistarihi);
						}
					}
				} catch (error) {
					console.log(error);
					//this.$router.push('/');
				}
				finally {
					loader.hide();
				}
			}
			else {
				//this.$router.push('/');
				loader.hide();
			}
		},
		async OnSave(kapat) {
			debugger;
			
			this.submitted = true;
			this.v$.$touch();

			if (this.v$.$invalid) {
				this.v$.$errors.forEach(element => {
					this.$toast.add({ severity: 'error', summary: 'Eksik alanlar var.', detail: element.$message, life: 3500 });
				});
			} else {
				try {
					if (this.selectedMobilKullanicilar) {
						if (this.selectedMobilKullanicilar.length > 0) {
							this.mainData["ekip"] = this.selectedMobilKullanicilar;
						}
					}

					this.mainData["entityId"] = this.entityId;
					const response = await this.crmService.getOtomasyonImalatUpdate(this.mainData);
					if (response) {
						if (response.hata == true) {
							this.$toast.add({severity:'error', summary: 'Kaydedilemedi', detail: response.hataAciklamasi, life: 5000});
						}else {
							this.$toast.add({severity:'success', summary: 'Bilgi', detail:'Başarıyla Kaydedildi', life: 3000});
							if (window.opener) {
								window.opener.window.postMessage({'tag': 'refresh'}, window.location.origin);
							}
							
							if (kapat) {
								setTimeout(() => {
									window.close();
								}, 1000);
							}
						}
					}
				} catch (error) {
					this.$toast.add({severity:'error', summary: 'Eksik alanlar var.', detail: error.message, life: 3500});
				}
			}
		},
		async OnDelete(){
            this.deleteItem(this.ENTITY_NAME, this.entityId);
        },
		OnDevreDisiBirak() {
			const postData = {
				logicalName: this.ENTITY_NAME,
				entiyId: this.entityId,
				stateCode: 1,
				statusCode: 2
			};

			this.devreDisiItem(postData);
		},
		OnYenidenEtkinlestirItem() {
			const postData = {
				logicalName: this.ENTITY_NAME,
				entiyId: this.entityId,
				stateCode: 0,
				statusCode: 1
			};

			this.yenidenEtkinlestirItem(postData);
		},
		enBuyukIlerlemeDegeri(){
			let bulundumu = false;
			for (let i = 100; i >= 10; i -= 10) {
				if (this.mainData["bm_ilerleme_check" + i] == true) {
					this.mainData["bm_ilerlemedurumu"] = i;
					bulundumu = true;
					break;
				}
			}

			if (bulundumu == false) {
				this.mainData["bm_ilerlemedurumu"] = null;
			}
		},
		getIlerlemeIcon(value) {
			if (value == 100) {
				return "layout/images/entityImages/process_100.png";
			}
			else if (value <= 50) {
				return "layout/images/entityImages/process_0.png";
			}
			else {
				return "layout/images/entityImages/process_50.png";
			}
		},
		handleScroll() {
			const el = document.getElementById('stickBar');

			if (window.scrollY > 50) {
				el.style.boxShadow = '0px 2px 1px rgba(0, 0, 0, 0.09), 0px 4px 2px rgba(0, 0, 0, 0.09), 0px 8px 4px rgba(0, 0, 0, 0.09), 0px 16px 8px rgba(0, 0, 0, 0.09), 0px 32px 16px rgba(0, 0, 0, 0.09)';
			}
			else {
				el.style.boxShadow = '';
			}
		},
	},
	watch: {
		profileData(val) {
			if (val) {
				if (val.moduller) {
					const filtered = val.moduller.filter(x => x.name == "Otomasyon Projesi");
					if (filtered.length == 0) {
						this.$router.replace({ name: 'accessdenied' });
					}
				}
			}
		}
	},
	validations() {
		return {
			mainData: {
				bm_baslangictarihi: {
					required : helpers.withMessage('Başlangıç alanı dolu olmalıdır!', required),
				},
				bm_bitistarihi: {
					required : helpers.withMessage('Bitiş Tarihi alanı dolu olmalıdır!', required),
				},
				bm_imalatcifirmaName: {
					required : helpers.withMessage('İmalatçı Firma alanı dolu olmalıdır!', required),
				},
				bm_tip: {
					required : helpers.withMessage('Tip alanı dolu olmalıdır!', required),
				},
				bm_urungrubu: {
					required : helpers.withMessage('Ürün Grubu alanı dolu olmalıdır!', required),
				},
			},
		}
	}
}
</script>

<style lang="scss" scoped>
	.stickBar {
		position: sticky;
		top: 6.2rem;
		z-index: 10;
	}

	.country-item {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
	}

	::v-deep(.multiselect-custom) {
		.p-multiselect-label:not(.p-placeholder) {
			padding-top: .50rem;
			padding-bottom: .50rem;
		}

		.country-item-value {
			padding: .25rem .5rem;
			border-radius: 3px;
			display: inline-flex;
			margin-right: .5rem;
			background-color: var(--primary-color);
			color: var(--primary-color-text);

			img.flag {
				width: 17px;
			}
		}
	}

	@media screen and (max-width: 640px) {
		.p-multiselect {
			width: 100%;
		}
	}


</style>
